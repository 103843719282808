// import {owlCarousel} from 'owlcarousel';
import {TimelineMax} from 'gsap';
import maskPlugin from 'jquery-mask-plugin';
import Splitter from 'split-html-to-chars';

let arr = ['Хороший выбор...', 'We make good shit...', 'Превертствуем тебя путник...'];
let rand = Math.floor(Math.random() * arr.length);

$('.loader .loader__text').text( arr[rand] );

$('input[name=phone]').mask('+38 000 000-00-00');

let windowHeight = $(document).height();
// console.log(windowHeight);
// $('.fs-section').height(windowHeight);

let els = document.querySelectorAll('.splitter');
[].forEach.call(els, function(el) {
  el.outerHTML = Splitter(el.outerHTML, '<span class="letter">$</span>');
});

// Menu Btn 
$('.mnu_btn').on('click', function(e) {
  e.preventDefault;
  $(this).toggleClass('mnu_btn__active');
  $('.header__nav').toggleClass('mnu_btn__active');
});

let tl = new TimelineMax();

let mssg = $('.message'),
  mssgBtn = $('.message-closeBtn'),
  mssgCookies = localStorage.getItem('mssg');

function showMssg() {
  if (localStorage.getItem('mssg') !== 'hidden') {
    mssg.addClass('visible');
  }
}

mssgBtn.on('click', function() {
  mssg.removeClass('visible');
  localStorage.setItem('mssg', 'hidden', { expires: 7 });
});

tl
  .staggerFromTo('.loader__text .letter', 0.3, {y:30, opacity: 0}, {y: 0, opacity: 1}, 0.03)
  .to('.loader', 1.5, {y: -windowHeight, height: 0}, 2)
  .fromTo('.header__logo', 0.3, {y:-30,opacity: 0}, {y: 0, opacity: 1}, '-=.5')
  .fromTo('.footer__mouse', 0.3, {y:30,opacity: 0}, {y: 0, opacity: 1}, '-=.5')
  .staggerFromTo('.header__nav li', 0.2, {y:-30,opacity: 0}, {y: 0, opacity: 1}, -0.05)
  .staggerFromTo('.header__title .letter', 0.3, {y:30, opacity: 0}, {y: 0, opacity: 1}, 0.02)
  .staggerFromTo('.header__subtitle, header .field', 0.5, {x:-50, opacity: 0}, {x: 0, opacity: 1, onComplete: showMssg}, 0.2);
	
// input 
$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.btn__submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field__input').addClass('empty_field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field__input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).addClass('field-not-empty');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).removeClass('field-not-empty');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.field-not-empty').css({'background-color':'#d8512d'});
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty_field').removeAttr('style');
      },500);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {

      btn.prop('disabled', true);
      $('body').add('<div class="success-man">').appendTo( document.body );
      let successMan = $('.success-man');
      $.ajax({
        type: 'POST',
        url: 'php/send.php', //Change
        data: form.serialize()
      }).done(function() {
        tl
          .to(successMan, 2.5, {x: windowHeight, y: -windowHeight, ease: Power2.easeIn, onComplete: function() {
            successMan.remove();
            btn.prop('disabled', false);
            form.trigger('reset');
          }});
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.field-not-empty').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty === 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
			
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
        // form.submit();
      }
    });
  });
});



// Хуйня
// const pressed = [];
// const secretCode = 'smm';

// window.addEventListener('keyup', (e) => {
//   console.log(e.key);
//   pressed.push(e.key);
//   pressed.splice(-secretCode.length - 1, pressed.length - secretCode.length);
//   if (pressed.join('').includes(secretCode)) {
//     console.log('DING DING!');
//   }
//   console.log(pressed);
// });
